import React, { useContext } from 'react';
import { AmbienteContext } from './AmbienteContext';
import { AplicaçãoContext } from './AplicaçãoContext';
import { Link } from './Roteador';
import './velits.scss';

type AplicaçãoProps = {
  children: JSX.Element | JSX.Element[] | string;
  header: string;
};
export function Aplicação({ children, header }: AplicaçãoProps) {
  const { firebase } = useContext(AmbienteContext);
  const { links } = useContext(AplicaçãoContext);

  const lis = links.map(({ text, props }, idx) => (
    <li key={idx}>
      <Link {...props}>{text}</Link>
    </li>
  ));

  if (!firebase) {
    throw new Error(
      "Variável de contexto 'firebase' indefinida. Provavelmente, definição " +
        'deva ser feita em <Ambiente />.'
    );
  }

  return (
    <div className='app'>
      <header>
        <nav>
          <h1>{header}</h1>
          <ul>
            {lis.length > 0 && lis}
            <li>
              <a href='#' onClick={() => firebase.signOut()}>
                Desconectar
              </a>
            </li>
          </ul>
        </nav>
      </header>
      <main>{children}</main>
    </div>
  );
}
