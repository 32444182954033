import {
  AmbienteContext,
  Aplicação,
  useRegistros,
  Download,
  Tabela2 as Tabela,
  useForm,
  OrderBy,
} from '@velits/velits-lib';

import React, { useContext, useState } from 'react';
import { Relatório } from './Relatorios';

export function MeusRelatorios() {
  const { auth } = useContext(AmbienteContext);
  const clienteId = auth?.user?.uid;

  //Caminho para dados dos relatórios
  const collectionPath = `clientes/${clienteId}/relatorios`;

  //Caminho relativo para arquivos de cada relatório
  const filePath = (clienteId, relatório) =>
    `relatorios/${clienteId}/${relatório.id}`;

  const [orderBy, setOrderBy] = useState<OrderBy | null>(['entrega', 'desc']);

  const [relatórios, setRelatórios] = useRegistros<Partial<Relatório>>(
    collectionPath,
    { orderBy: orderBy && [orderBy] }
  );

  const readOnly = true;

  return (
    !!clienteId && (
      <Aplicação header='Meus Relatórios'>
        <Tabela
          show={[
            'descricao',
            'inspetor',
            'previsaoEntrega',
            'entrega',
            'nomeArquivo',
          ]}
          registros={relatórios}
          setRegistros={setRelatórios}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        >
          {{
            descricao: {
              title: 'Descrição',
              type: 'text',
              name: 'descricao',
              readOnly,
            },
            inspetor: {
              title: 'Inspetor',
              type: 'text',
              name: 'inspetor',
              readOnly,
            },
            previsaoEntrega: {
              title: 'Previsão de Entrega',
              type: 'date',
              name: 'previsaoEntrega',
              readOnly,
            },
            entrega: {
              title: 'Entrega',
              name: 'entrega',
              type: 'datetime-local',
              readOnly,
            },
            nomeArquivo: {
              title: 'Download',
              render: function _Download(reg) {
                return <DownloadRelatório path={filePath(clienteId, reg)} />;
              },
            },
          }}
        </Tabela>
      </Aplicação>
    )
  );
}

function DownloadRelatório({ path }: { path: string }) {
  const [relatório] = useForm<Relatório>();

  return (
    !!relatório.nomeArquivo &&
    !!relatório.entrega && (
      <Download fileName={relatório.nomeArquivo} path={path} />
    )
  );
}
