import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { AmbienteContext } from './AmbienteContext';
import { Firebase, FirebaseConfig } from './firebase-local';
import { useAuth } from './firebase/hooks';

type AmbienteProps = {
  firebaseConfig: FirebaseConfig;
  children?: JSX.Element;
  functions?: { [prop: string]: (...params: any) => any };
};
export function Ambiente({
  firebaseConfig,
  children,
  functions,
}: AmbienteProps) {
  const firebase = Firebase.init(firebaseConfig);
  const auth = useAuth(firebase);

  return (
    <AmbienteContext.Provider value={{ firebase, auth, functions }}>
      <Router>{children}</Router>
    </AmbienteContext.Provider>
  );
}
