import { AuthState } from '@velits/velits-lib';

export type Papel = 'superadmin' | 'auxiliar' | 'inspetor' | 'cliente';

export const papeisAdmin: Papel[] = ['superadmin', 'auxiliar'];

/**
 *
 * @param auth
 * @deprecated hasInterceção(auth?papeis, ['cliente'])
 */

export function isCliente(auth: AuthState) {
  return !!auth.papeis?.includes('cliente');
}
/**
 *
 * @param auth
 * @deprecated Utilizar hasInterceção(auth?papeis, papeisAdmin)
 */

export function isAdmin(auth: AuthState) {
  return !!auth.papeis?.some((papel: Papel) => {
    return papeisAdmin.includes(papel);
  });
}
