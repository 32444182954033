import React, { useContext, useState } from 'react';
import { AmbienteContext } from 'src/AmbienteContext';
import { Input } from 'src/Tabela/coluna';
import { FormContext } from 'src/Tabela/FormContext';
import { Link } from './Roteador';

type CriarContaProps = { header: string };
export function CriarConta({ header }: CriarContaProps) {
  const { firebase } = useContext(AmbienteContext);
  const [user, setUser] = useState({ email: '', senha: '' });

  const createUser = () => {
    firebase.autoCreateUser(user).catch(alert);
  };

  return (
    <section className='app'>
      <FormContext.Provider value={[user, setUser]}>
        <form style={{ width: '330px', margin: '32px auto' }}>
          <h1>{header} - Crie sua conta</h1>
          <p>Somente usuários previamente autorizados têm acesso ao sistema.</p>

          <label htmlFor='email'>E-mail</label>
          <Input name='email' />

          <label htmlFor='senha'>Senha</label>
          <Input name='senha' type='password' />

          <button type='button' onClick={createUser}>
            Criar conta
          </button>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <Link to='login' style={{ fontWeight: 'normal' }}>
              Login
            </Link>
          </div>
        </form>
      </FormContext.Provider>
    </section>
  );
}
