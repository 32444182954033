import React, { Dispatch, useContext } from 'react';
import { Registro } from '../dataModel';

export const FormContext = React.createContext<
  [any, Dispatch<Partial<any>>] | []
>([]);

export function useForm<R extends Registro>() {
  return useContext(
    FormContext as React.Context<[Partial<R>, Dispatch<Partial<R>>] | []>
  );
}
