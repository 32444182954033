import {
  AmbienteContext,
  Aplicação,
  CriarConta,
  Login,
  RecuperarSenha,
  Roteador,
} from '@velits/velits-lib';
import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Clientes } from './Clientes';
import { MeusRelatorios } from './MeusRelatórios';
import { Relatorios } from './Relatorios';
import { useRotas } from './useRotas';
import { Usuários } from './Usuários';
import '@velits/velits-lib/lib/velits.css';
import { Versão } from './Versão';

export function App() {
  const { auth } = useContext(AmbienteContext);
  const { show, curinga, links } = useRotas();

  const { path: _path } = useRouteMatch();
  const path = _path === '/' ? '' : _path; //Trata casos em que a url refere-se ao caminho raíz

  return (
    <>
      <Versão />
      {!auth ? (
        'Carregando autenticador'
      ) : auth.loading ? (
        `Carregando(${auth.loading})...`
      ) : (
        <Roteador curinga={curinga} show={show} links={links}>
          {{
            clientes: {
              text: 'Clientes',
              path: `${path}/clientes`,
              exact: true,
              children: (
                <Aplicação header='Clientes'>
                  <Clientes />
                </Aplicação>
              ),
            },
            relatórios: {
              path: `${path}/clientes/:clienteId/relatorios`,
              children: <Relatorios />,
            },
            usuários: {
              text: 'Usuários',
              path: `${path}/usuarios`,
              exact: true,
              children: (
                <Aplicação header='Usuários'>
                  <Usuários />
                </Aplicação>
              ),
            },
            meus_relatórios: {
              text: 'Meus Relatórios',
              path: `${path}/meusrelatorios`,
              children: <MeusRelatorios />,
            },
            login: {
              path: `${path}/login`,
              children: <Login header='EndTest' />,
            },
            criar_conta: {
              path: `${path}/criar-conta`,
              children: <CriarConta header='EndTest' />,
            },
            recuperar_senha: {
              path: `${path}/recuperar-senha`,
              children: <RecuperarSenha header='EndTest' />,
            },
            no_access: {
              path: `${path}`,
              children: (
                <Aplicação header='Acesso Negado'>
                  <p>Desculpe, você não possui acesso ao sistema.</p>
                  <p>
                    Entre em contato com a administração para solicitar acesso.
                  </p>
                  <p>Obrigado,</p>
                </Aplicação>
              ),
            },
          }}
        </Roteador>
      )}
    </>
  );
}
