import React, { useContext, useState } from 'react';
import { AmbienteContext } from 'src/AmbienteContext';
import { Input } from 'src/Tabela/coluna';
import { FormContext } from 'src/Tabela/FormContext';
import { Link } from './Roteador';

type LoginProps = { header: string };
export function Login({ header }: LoginProps) {
  const { firebase } = useContext(AmbienteContext);
  const [user, setUser] = useState({ email: '', senha: '' });

  const login = () => {
    firebase.signIn(user).catch(alert);
  };

  return (
    <section className='app'>
      <FormContext.Provider value={[user, setUser]}>
        <form style={{ width: '330px', margin: '32px auto' }}>
          <h1>{header}</h1>

          <label htmlFor='email'>E-mail</label>
          <Input name='email' />

          <label htmlFor='senha'>Senha</label>
          <Input name='senha' type='password' />

          <button type='button' onClick={login}>
            Entrar
          </button>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <Link to='criar-conta' style={{ fontWeight: 'normal' }}>
              Criar conta
            </Link>
            <Link
              to='recuperar-senha'
              style={{ fontWeight: 'normal', textAlign: 'right' }}
            >
              Esqueceu a senha?
            </Link>
          </div>
        </form>
      </FormContext.Provider>
    </section>
  );
}
