import React, { useContext, useState } from 'react';
import { AmbienteContext } from 'src/AmbienteContext';
import { Input } from 'src/Tabela/coluna';
import { FormContext } from 'src/Tabela/FormContext';
import { Link } from './Roteador';

type RecuperarSenhaProps = { header: string };
export function RecuperarSenha({ header }: RecuperarSenhaProps) {
  const { firebase } = useContext(AmbienteContext);
  const [user, setUser] = useState({ email: '' });

  const createUser = () => {
    if (!user.email) {
      alert('Obrigatório preencher e-mail');
      return;
    }
    firebase
      .sendPasswordResetEmail(user.email)
      .then(() =>
        alert(`E-mail para recuperação de senha foi enviado para ${user.email}`)
      );
  };

  return (
    <section className='app'>
      <FormContext.Provider value={[user, setUser]}>
        <form style={{ width: '330px', margin: '32px auto' }}>
          <h1>{header} - Recuperar senha</h1>

          <label htmlFor='email'>E-mail</label>
          <Input name='email' />

          <button type='button' onClick={createUser}>
            Recuperar senha
          </button>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <Link to='login' style={{ fontWeight: 'normal' }}>
              Login
            </Link>
          </div>
        </form>
      </FormContext.Provider>
    </section>
  );
}
