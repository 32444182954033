import React, {
  Dispatch,
  OptionHTMLAttributes,
  useContext,
  useRef,
} from 'react';
import { Dado, Registro } from '../dataModel';
import { Coluna } from './Tabela';
import { useForm } from './FormContext';
import { dataFormat } from '../util';
import { AmbienteContext } from 'src/AmbienteContext';
import { useDownloadUrl } from 'src/firebase/hooks';

type Target = EventTarget & (HTMLInputElement | HTMLSelectElement);

type Type = 'text' | 'date' | 'datetime-local' | 'password';

export type Atributos = {
  name: string;
  title?: string;
  readOnly?: boolean;
  type?: Type;
};

type Célula<R> = (
  registro: Partial<R>,
  setRegistro: Dispatch<Partial<R>>
) => JSX.Element | string;

type BotãoSalvarProps<R> = {
  onSave: (
    registro: Partial<R>,
    setRegistro: React.Dispatch<Partial<R>>
  ) => void;
};

type UploadButtonProps = {
  path: string;
  onUpload: ({ name: string }) => void;
};

type DownloadProps = { fileName: string; path: string };

/**
 * Produzir definições de colunas com <input/> a partir dos atributos deste.
 * @param cabeçalho Cabeçalho da coluna
 * @param atributos
 * @deprecated Utilizar <Input/>
 */
export function input<R extends Registro>(
  cabeçalho: string,
  atributos: Atributos
): Coluna<R> {
  const componente = () => <Input {...atributos} />;
  return { cabeçalho, componente };
}

export function Input(atributos: Atributos): JSX.Element {
  const [registro, setRegistro] = useForm();

  //Manipulador mudança de estado
  const handleChange = ({ target }: { target: Target }) => {
    changeDado(registro, target.name, target.value);
    setRegistro({ ...registro });
  };

  // Provoca a chamada de trim sobre o dado
  const handleBlur = ({ target }: { target: Target }) => {
    changeDado(registro, target.name, target.value, { trim: true });
    setRegistro({ ...registro });
  };

  const dado = getDado(registro, atributos.name);

  if (atributos.readOnly) {
    return <>{getValor(dado, atributos.type)}</>;
  } else {
    return (
      <input
        {...{
          value: dado,
          onChange: handleChange,
          onBlur: handleBlur,
          id: atributos.name,
          ...atributos,
        }}
      />
    );
  }
}

export type OptionProps<V> = OptionHTMLAttributes<HTMLOptionElement> & {
  value?: V;
  inner?: string;
};

type SelectProps<V> = { name: string; options: OptionProps<V>[] };
export function Select<V>(props: SelectProps<V>): JSX.Element {
  const [registro, setRegistro] = useForm();

  //Manipulador mudança de estado
  const handleChange = ({ target }: { target: Target }) => {
    changeDado(registro, target.name, target.value);
    setRegistro({ ...registro });
  };

  const dado = getDado(registro, props.name);
  if (typeof dado !== 'string' && typeof dado !== 'undefined') {
    throw new Error(
      `Sem suporte a typeof ${typeof dado}. Campo ${
        props.name
      }, valor ${JSON.stringify(dado)}`
    );
  }

  return (
    <select value={dado || ''} onChange={handleChange} {...props}>
      {props.options.map(({ inner, ...props }, key: number) => (
        <option key={key} {...props}>
          {inner}
        </option>
      ))}
    </select>
  );
}

/**
 * @deprecated
 * @param param0
 */
export function botãoSalvar<R extends Registro>({
  onSave: onClick,
}: BotãoSalvarProps<R>): Célula<R> {
  return function Salvar(
    registro: Partial<R>,
    setRegistro: Dispatch<Partial<R>>
  ) {
    return (
      <span
        role='img'
        aria-label='salvar'
        style={{ cursor: 'pointer' }}
        onClick={() => onClick(registro, setRegistro)}
      >
        💾
      </span>
    );
  };
}

type SalvarProps<R> = {
  onClick: (registro: Partial<R>, setRegistro: Dispatch<Partial<R>>) => void;
};
export function Salvar<R extends Registro>({ onClick }: SalvarProps<R>) {
  const [registro, setRegistro] = useForm<R>();
  return (
    <span
      role='img'
      aria-label='salvar'
      style={{ cursor: 'pointer' }}
      onClick={() => onClick(registro, setRegistro)}
    >
      💾
    </span>
  );
}

function getValor(dado: string, type: Type): string {
  if (type === 'date' || type === 'datetime-local') {
    return dataFormat(dado as string | undefined);
  } else if (Array.isArray(dado)) {
    const v = dado as string[];
    return v.join(', ');
  } else {
    return (dado as string) || '';
  }
}

/**
 * Retorna dado contido na última posição.
 * Lança exceção caso o dado na última posição seja diferente de string, null ou
 * undefided.
 * @param registro
 * @param name
 */
function getDado<R extends Registro>(
  registro: Partial<R>,
  name: string
): string {
  const fields = name.split('.');
  let curr: Dado = registro;

  // Itera objeto até a última referência
  for (const field of fields) {
    curr = curr[field] as Dado;
    if (curr === undefined || curr === null) return '';
    if (typeof curr === 'string') return curr;
  }

  throw new Error(
    `Sem suporte a typeof ${typeof curr}. Campo: ${name}. Valor: ${JSON.stringify(
      curr
    )}`
  );
}

/**
 * Produz efeito colateral sobre 'registro' atribuindo valor à última posição referenciada
 * @param registro
 * @param name
 * @param value
 */
function changeDado<R extends Dado>(
  registro: Partial<R>,
  name: string,
  value: string,
  options = { trim: false }
) {
  const fields = name.split('.');
  let dado: Dado = registro;

  // Itera objeto até a penúltima referência
  for (let i = 0; i < fields.length - 1; i++) {
    const field = fields[i];

    //Cria dado de referência caso não exista
    if (dado[field] === undefined) {
      const nextField = fields[i + 1];
      dado[field] = Number.isInteger(+nextField) ? [] : {};
    }

    dado = dado[field] as Dado;
  }

  const lastField = fields[fields.length - 1];
  dado[lastField] = options.trim ? value.trim() : value; //Atribui valor à última posição
}

/**
 * Função auxiliar para definição de objeto descritor de uma coluna de <Tabela/>
 * @param cabeçalho Título da coluna
 * @param componente criador de componente capaz de ler ou editar registro
 */
export function coluna<R>(cabeçalho: string, componente: Célula<R>): Coluna<R> {
  return { cabeçalho, componente };
}

export function UploadButton({ path, onUpload }: UploadButtonProps) {
  const { firebase } = useContext(AmbienteContext);
  const inputFile = useRef(null);
  return (
    <>
      <button onClick={() => inputFile.current?.click()}>Upload</button>
      <input
        ref={inputFile}
        type='file'
        multiple={false}
        style={{ display: 'none' }}
        onChange={({ target }) => {
          if (target.files?.length) {
            firebase
              .upload(target.files[0], path)
              .then(({ name }) => onUpload({ name }))
              .catch(alert);
          }
        }}
      />
    </>
  );
}

export function Download({ fileName, path }: DownloadProps) {
  const url = useDownloadUrl(`${path}/${fileName}`);
  return (
    <a target='_blank' href={url} rel='noopener noreferrer'>
      {fileName}
    </a>
  );
}
