import {
  AmbienteContext,
  hasInterceção,
  RoteadorShow,
} from '@velits/velits-lib';
import { useContext, useEffect, useState } from 'react';
import { papeisAdmin } from './papel';

export function useRotas(): RoteadorShow {
  const { auth } = useContext(AmbienteContext);
  const [rotas, setRotas] = useState<RoteadorShow>({
    show: [],
    curinga: '',
    links: [],
  });

  useEffect(() => {
    if (!auth) return;

    if (!auth.user) {
      setRotas({
        show: ['login', 'criar_conta', 'recuperar_senha'],
        curinga: 'login',
        links: [],
      });
    } else {
      const aux: RoteadorShow = { show: [], curinga: '', links: [] };
      if (auth.papeis && auth.papeis.length > 0) {
        //Administrador
        if (hasInterceção(auth.papeis, papeisAdmin)) {
          aux.show.push('clientes', 'relatórios', 'usuários');
          aux.links.push('clientes', 'usuários');
        }

        //Inspetor
        if (hasInterceção(auth.papeis, ['inspetor'])) {
          aux.show.push('clientes', 'relatórios');
          aux.links.push('clientes');
        }

        //Cliente
        if (hasInterceção(auth.papeis, ['cliente'])) {
          aux.show.push('meus_relatórios');
          aux.links.push('meus_relatórios');
        }

        //Definição da rota curinga
        if (aux.show.includes('clientes')) {
          aux.curinga = 'clientes';
        } else if (aux.show.includes('meus_relatórios')) {
          aux.curinga = 'meus_relatórios';
        }
      }

      setRotas(
        aux.show.length
          ? aux
          : { show: ['no_access'], curinga: 'no_access', links: [] }
      );
    }
  }, [auth]);

  return rotas;
}
