/* eslint-disable @typescript-eslint/no-var-requires */
// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/app';
import { Credenciais, Papel, Registro } from './dataModel';

// Add the Firebase services that you want to use
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

type OrderByDirection = firebase.firestore.OrderByDirection;
export type OrderBy = [fieldPath: string, direction: OrderByDirection];
type Query = firebase.firestore.Query;
type WhereFilterOp = firebase.firestore.WhereFilterOp;

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}

export type AuthState = {
  loading: 'usuário' | 'papeis' | false;
  user?: firebase.User;
  papeis?: Papel[];
};

export class Firebase {
  private readonly app: firebase.app.App;
  private readonly firestore: firebase.firestore.Firestore;
  private readonly functions: firebase.functions.Functions;
  private readonly storage: firebase.storage.Storage;
  private readonly auth: firebase.auth.Auth;

  private constructor(config: any) {
    this.app = firebase.initializeApp(config);
    this.firestore = this.app.firestore();
    this.functions = this.app.functions();
    this.storage = this.app.storage();
    this.auth = this.app.auth();

    if (window.location.hostname === 'localhost') {
      this.firestore.useEmulator('localhost', 8080);
      this.functions.useEmulator('localhost', 5001);
      this.auth.useEmulator('http://localhost:9099/');
      this.storage.useEmulator('localhost', 9199);
    }
  }

  static instance: Firebase;
  static init(config: FirebaseConfig) {
    if (!this.instance) this.instance = new Firebase(config);
    return this.instance;
  }

  async one<R extends Partial<Registro>>(
    documentPath: string
  ): Promise<R | undefined> {
    const snapshot = await this.firestore.doc(documentPath).get();
    if (snapshot.exists) {
      return { id: snapshot.id, ...snapshot.data() } as R;
    }
  }

  async set<R extends Partial<Registro>>(
    collectionPath: string,
    data: R,
    novo = false
  ): Promise<R> {
    const serverTime = firebase.firestore.FieldValue.serverTimestamp();
    if (data.id === undefined || data.id === null) {
      //Inserção de novo registro desconhecendo-se seu id
      const { id } = await this.firestore
        .collection(collectionPath)
        .add({ ...data, _inserted_at: serverTime });

      return { ...data, id };
    } else {
      const id = data.id; //captura id do dado
      const d = { ...data }; //cria novo objeto copiando dados
      delete d.id; //remove id para não inseri-lo corpo do objeto

      if (novo) {
        /**
         * Inserção de registro quando id inexistente no servidor
         * ou reset do registro caso id exista no servidor
         */
        await this.firestore
          .collection(collectionPath)
          .doc(id)
          .set({ ...d, _inserted_at: serverTime }, { merge: true });
      } else {
        //Atualização de registro existente no banco de dados
        await this.firestore
          .collection(collectionPath)
          .doc(id)
          .update({ ...d, _updated_at: serverTime });
      }

      return { ...data };
    }
  }

  /**
   * Consulta vários registros.
   * @param collectionPath Caminho da coleção sendo consultada
   * @param conditions Condições para a consulta
   * @param options Opções para recuperação de registros
   * @returns arranjo de registros recuperados
   */
  async many<R extends Partial<Registro>>(
    collectionPath: string,
    conditions?: [string, WhereFilterOp, string][],
    options: { orderBy?: OrderBy[] } = {}
  ): Promise<R[]> {
    try {
      let query: Query = this.firestore.collection(collectionPath);

      if (conditions) {
        conditions.forEach(([fieldPath, opStr, value]) => {
          query = query.where(fieldPath, opStr, value);
        });
      }

      if (options.orderBy) {
        options.orderBy.forEach(([fieldPath, direction]) => {
          query = query.orderBy(fieldPath, direction);
        });
      }

      const snapshot = await query.get();
      return snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id } as R;
      });
    } catch (e) {
      switch (e.code) {
        case 'permission-denied':
          throw new Error('Permissão negada');
        default:
          throw e;
      }
    }
  }

  /* ------------------------------ Functions ------------------------------ */

  /**
   *
   * @param param0
   * @returns identificador (uid) do usuário recém criado
   */
  async createUser({ email, senha }): Promise<string> {
    const createUser = this.functions.httpsCallable('createUser');
    const { data: user } = await createUser({ email, senha });
    return user.uid as string;
  }

  async updateUser({ id, email, senha }) {
    const updateUser = this.functions.httpsCallable('updateUser');
    await updateUser({ uid: id, email, senha });
  }

  async sendEmail(data: any) {
    const send = this.functions.httpsCallable('sendEmail');
    await send(data);
  }

  /* ------------------------------- Storage -------------------------------  */

  async upload(file: File, path: string) {
    try {
      const folderRef = this.storage.ref(path);

      /**
       * Exclusão de todos arquivos do bucket para garantir a
       * existência de apenas um arquivo no local
       */
      const list = await folderRef.listAll();
      for (let i = 0; i < list.items.length; i++) {
        const itemRef = list.items[i];
        await itemRef.delete();
      }

      const fileRef = folderRef.child(file.name);
      const uploadTask = fileRef.put(file);
      const snapshot = await uploadTask;
      return { name: snapshot.metadata.name };
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  }

  async downloadURL(path: string) {
    const fileRef = this.storage.ref(path);
    return await fileRef.getDownloadURL();
  }

  /*-------------------------------Autenticação ------------------------------*/
  async signIn({ email, senha: password }: Credenciais) {
    try {
      return await this.auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      if (e.code === 'auth/wrong-password') {
        throw new Error('Senha inválida');
      } else if (
        e.code === 'auth/invalid-email' ||
        e.code === 'auth/user-not-found'
      ) {
        throw new Error('E-mail inválido');
      } else {
        throw new Error(e);
      }
    }
  }

  signOut() {
    return this.auth.signOut();
  }

  autoCreateUser({ email, senha: password }: Credenciais) {
    return this.auth
      .createUserWithEmailAndPassword(email, password)
      .catch((error) => {
        switch (error.code) {
          case 'auth/email-already-in-use':
            alert(
              'Usuário não criado.\n' +
                'E-mail já utilizado em outra conta.\n' +
                'Não é possível criar outra conta com este e-mail.'
            );
            break;
          case 'auth/invalid-email':
            alert('Usuário não criado.\n' + 'Endereço de e-mail inválido.');
            break;
          case 'auth/operation-not-allowed':
            alert(
              'Usuário não criado\n' +
                'Método de autenticação (email + senha) desabilitado.'
            );
            break;
          case 'auth/weak-password':
            alert('Usuário não criado\n' + 'Senha muito fraca. ');
            break;
          default:
            alert(error.code + ' - ' + error.message);
            break;
        }
      });
  }

  async sendPasswordResetEmail(email: string) {
    try {
      return await this.auth.sendPasswordResetEmail(email);
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          alert('Endereço de e-mail inválido.');
          break;
        case 'auth/user-not-found':
          alert('Não existe usuário cadastrado com este e-mail.');
          break;
        default:
          alert(error.code + ' - ' + error.message);
          break;
      }
    }
  }

  authChange(onChange: (user: firebase.User) => void) {
    return this.auth.onAuthStateChanged(onChange);
  }
}
