import React, { Dispatch } from 'react';
import { Registro } from '../dataModel';
import { FormContext } from './FormContext';

export type Coluna<R> = {
  cabeçalho: string;
  componente: (
    registro: Partial<R>,
    setRegistro: Dispatch<Partial<R>>
  ) => JSX.Element | string;
};

/**
 *
 * @param param0
 * @deprecated utilizar arquivo Tabela2.tsx
 */
export function Tabela<R extends Registro>({
  registros,
  setRegistros,
  colunas,
  botãoNovo,
}: {
  registros: Partial<R>[];
  setRegistros: React.Dispatch<React.SetStateAction<Partial<R>[]>>;
  colunas: Coluna<Partial<R>>[];
  botãoNovo?: string;
}) {
  const cabeçalhos = colunas.map(({ cabeçalho }, key) => (
    <th key={key}>{cabeçalho}</th>
  ));

  const linhas = registros?.map((regitro, idx) => (
    <Linha
      key={regitro.id || idx}
      registro={regitro}
      setRegistro={(registro: Partial<R>) => {
        registros[idx] = registro;
        setRegistros([...registros]);
      }}
      colunas={colunas}
    />
  ));

  const novo = botãoNovo && registros && (
    <div>
      <button aria-label='Novo' onClick={() => setRegistros((c) => [{}, ...c])}>
        {botãoNovo}
      </button>
    </div>
  );

  return (
    <>
      {novo}
      {registros && (
        <table>
          <thead>
            <tr>{cabeçalhos}</tr>
          </thead>
          <tbody>{linhas}</tbody>
        </table>
      )}
    </>
  );
}

function Linha<R extends Registro>({
  registro,
  setRegistro,
  colunas,
}: {
  registro: Partial<R>;
  setRegistro: Dispatch<Partial<R>>;
  colunas: Coluna<Partial<R>>[];
}) {
  return (
    <FormContext.Provider value={[registro, setRegistro]}>
      <tr>
        {colunas.map((campo, key) => (
          <td key={key}>{campo.componente(registro, setRegistro)}</td>
        ))}
      </tr>
    </FormContext.Provider>
  );
}
