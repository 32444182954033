import {
  useEditor,
  useRegistros,
  Tabela,
  coluna,
  Input,
  Salvar,
  OptionProps,
  Select,
  Usuário,
} from '@velits/velits-lib';
import React from 'react';
import { Papel } from './papel';

export function Usuários() {
  const [usuários, setUsuários] = useRegistros<Partial<Usuário>>('usuarios');
  const [save] = useEditor<Usuário>('usuarios', {
    id: null,
    nome: null,
    papeis: null,
    email: null,
  });

  const success = () => alert('Usuário salvo');

  const options: OptionProps<Papel>[] = [
    { disabled: true },
    { value: 'superadmin', inner: 'Administrador Geral' },
    { value: 'auxiliar', inner: 'Auxiliar Administrativo' },
    { value: 'inspetor', inner: 'Inspetor' },
  ];

  return (
    <Tabela
      botãoNovo='Novo Usuário'
      registros={usuários}
      setRegistros={setUsuários}
      colunas={[
        coluna('', (reg, set) => (
          <Salvar onClick={() => save(reg).then(set).then(success)} />
        )),
        coluna('Nome', () => <Input name='nome' />),
        coluna('Papel', () => <Select name='papeis.0' options={options} />),
        coluna('E-mail*', () => <Input name='email' />),
      ]}
    />
  );
}
