import {
  AmbienteContext,
  Aplicação,
  Download,
  formatDate,
  hasInterceção,
  OrderBy,
  Registro,
  Salvar,
  Tabela2 as Tabela,
  UploadButton,
  useEditor,
  useForm,
  useRegistros,
  useUmRegistro,
} from '@velits/velits-lib';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Cliente } from './Clientes';
import { papeisAdmin } from './papel';

export interface Relatório extends Registro {
  id: string;
  descricao: string;
  inspetor: string;
  previsaoEntrega: string;
  entrega: string;
  nomeArquivo: string;
}

function useColunas() {
  const {
    auth: { papeis },
  } = useContext(AmbienteContext);

  const colunasInspetor = [
    'salvar',
    'descricao',
    'inspetor',
    'previsaoEntrega',
    'entrega',
    'upload',
    'nomeArquivo',
  ];

  const isAdmin = papeis && hasInterceção(papeis, papeisAdmin);
  if (isAdmin) return [...colunasInspetor, 'entregar'];

  const onlyInspetor = papeis?.length === 1 && papeis[0] === 'inspetor';
  if (onlyInspetor) return colunasInspetor;

  return [];
}

export function Relatorios() {
  const { clienteId } = useParams<{ clienteId: string }>();

  //Caminhos para armazenar relatórios e arquivos, respectivamente
  const collectionPath = `clientes/${clienteId}/relatorios`;

  const [cliente] = useUmRegistro<Partial<Cliente>>(`clientes/${clienteId}`);

  const [orderBy, setOrderBy] = useState<OrderBy | null>(['entrega', 'desc']);
  const [relatórios, setRelatórios] = useRegistros<Partial<Relatório>>(
    collectionPath,
    { orderBy: orderBy && [orderBy] }
  );

  const [saveRelatório] = useEditor<Relatório>(collectionPath, {
    id: null,
    descricao: null,
    inspetor: null,
    previsaoEntrega: null,
    entrega: null,
    nomeArquivo: null,
  });
  const colunas = useColunas();

  //Definição do cabeçalho
  const clienteNome = cliente?.nome ? `- ${cliente.nome}` : '';
  const clienteResp = cliente?.responsavel ? `- ${cliente.responsavel}` : '';
  const header = `Relatórios ${clienteNome} ${clienteResp}`;

  const filePath = (clienteId, relatório) =>
    `relatorios/${clienteId}/${relatório.id}`;

  return (
    <Aplicação header={header}>
      <Tabela
        botãoNovo='Novo Relatório'
        show={colunas}
        registros={relatórios}
        setRegistros={setRelatórios}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      >
        {{
          salvar: {
            render: function _Salvar() {
              return (
                <Salvar
                  onClick={(reg, setReg) =>
                    saveRelatório(reg)
                      .then(setReg)
                      .then(() => alert('Relatório salvo'))
                  }
                />
              );
            },
          },
          descricao: { title: 'Descrição', type: 'text', name: 'descricao' },
          inspetor: { title: 'Inspetor', type: 'text', name: 'inspetor' },
          previsaoEntrega: {
            title: 'Previsão de Entrega',
            type: 'date',
            name: 'previsaoEntrega',
          },
          upload: {
            render: function _Upload(reg) {
              return (
                <UploadRelatório
                  path={filePath(clienteId, reg)}
                  {...{ saveRelatório, cliente }}
                />
              );
            },
          },
          nomeArquivo: {
            title: 'Download',
            render: function _Download(reg) {
              return <DownloadRelatório path={filePath(clienteId, reg)} />;
            },
          },
          entrega: {
            title: 'Entrega',
            name: 'entrega',
            type: 'datetime-local',
            readOnly: true,
          },
          entregar: {
            render: function _Entregar() {
              return <Entregar {...{ saveRelatório, cliente }} />;
            },
          },
        }}
      </Tabela>
    </Aplicação>
  );
}

type UploadProps = {
  saveRelatório: (
    registro: Partial<Registro>,
    novo?: boolean
  ) => Promise<Partial<Registro>>;
  cliente: Partial<Cliente>;
  path: string;
};

function UploadRelatório({
  saveRelatório,
  path,
  cliente,
}: UploadProps): JSX.Element {
  const [relatório, setRelatório] = useForm<Relatório>();

  const { functions, firebase } = useContext(AmbienteContext);
  const enviarEmailUpload = functions.enviarEmailUpload;

  return !relatório.id ? null : (
    <UploadButton
      path={path}
      onUpload={async (metadados) => {
        try {
          await enviarEmailUpload(firebase.sendEmail.bind(firebase), cliente);

          saveRelatório({ ...relatório, nomeArquivo: metadados.name })
            .then(setRelatório)
            .then(() => alert('Upload realizado com sucesso.'));
        } catch (e) {
          alert(JSON.stringify({ e, message: e.message }));
        }
      }}
    />
  );
}

type EntregarProps = {
  saveRelatório: (
    registro: Partial<Registro>,
    novo?: boolean
  ) => Promise<Partial<Registro>>;
  cliente: Partial<Cliente>;
};
function Entregar({ saveRelatório, cliente }: EntregarProps) {
  const [relatório, setRelatório] = useForm<Relatório>();

  const { functions, firebase } = useContext(AmbienteContext);
  const enviarEmailRelatório = functions.enviarEmailRelatório;

  const onClick = async () => {
    const resposta = confirm(
      'Um e-mail será enviado ao cliente informando sobre este relatório.\n' +
        'Tem certeza que deseja enviá-lo? Essa ação não pode ser desfeita.'
    );
    if (resposta === true) {
      try {
        await enviarEmailRelatório(firebase.sendEmail.bind(firebase), cliente);

        relatório.entrega = formatDate(new Date()); //Atribui data de entrega
        saveRelatório(relatório) //salva relatório
          .then(setRelatório) //Atualiza estado do relatório
          .then(() => alert('Relatório entregue ao cliente'));
      } catch (e) {
        alert(JSON.stringify({ e, message: e.message }));
      }
    }
  };

  //Somente envia relatórios existentes e com arquivo anexado
  return !relatório.nomeArquivo ? null : (
    <button onClick={onClick}>Entregar</button>
  );
}

function DownloadRelatório({ path }: { path: string }) {
  const [relatório] = useForm<Relatório>();

  return (
    !!relatório.nomeArquivo && (
      <Download fileName={relatório.nomeArquivo} path={path} />
    )
  );
}
