import { Dispatch } from 'react';

export type Papel =
  | 'cliente'
  | 'superadmin'
  | 'auxiliar'
  | 'inspetor'
  | undefined;

export function handleStateChange<A>(state: A, setState: Dispatch<A>) {
  return ({
    target: { name, value },
  }: {
    target: EventTarget & (HTMLInputElement | HTMLSelectElement);
  }) => {
    setState({ ...state, [name]: value });
  };
}

// export function validate(state: Partial<Usuário>, definições: any[]) {
//   /* todos os campos de definições são obrigatórios
//   Exemplo:
//   const required = [
//     { header: 'E-mail', name: 'email' },
//     { header: 'Senha', name: 'senha', minLength: 6 },
//   ];*/

//   const msgs = definições.reduce(
//     (
//       msgs,
//       {
//         header,
//         name,
//         minLength,
//       }: { header: any; name: keyof Usuário; minLength: any }
//     ) => {
//       let aux = msgs;

//       if (!state[name]) {
//         aux = [...aux, `Campo obrigatório: ${header}`];
//       } else if (minLength && state[name].length < minLength) {
//         aux = [...aux, `${header} deve ter no mínimo ${minLength} caracteres`];
//       }

//       return aux;
//     },
//     []
//   );

//   if (msgs.length > 0) {
//     Promise.resolve().then(() => alert(msgs.join('\n')));
//   }

//   return msgs.length === 0;
// }

/**
 * Retorna data em formato YYYY-MM-DDThh:mm:ss.SSS
 *
 * @param d Data
 */
export function formatDate(d: Date) {
  const pad = (n: number) => (n < 10 ? `0${n}` : n);
  const ano = d.getFullYear();
  const mês = pad(d.getMonth() + 1);
  const dia = pad(d.getDate());
  const horas = pad(d.getHours());
  const minutos = pad(d.getMinutes());
  const segundos = pad(d.getSeconds());
  const milisegundos = d.getMilliseconds().toString().padStart(4, '0');

  return `${ano}-${mês}-${dia}T${horas}:${minutos}:${segundos}.${milisegundos}`;
}

/**
 * Retorna data no formato DD/MM/YYYY HH:mm
 *
 * @param d Data no formato YYYY-MM-DD ou YYYY-MM-DDTHH:mm
 */
export function dataFormat(d: string | undefined) {
  if (!d) return '';

  const [data, hora] = d.split('T');
  const [ano, mes, dia] = data.split('-');
  if (hora) {
    const [HH, mm] = hora.split(':');
    return `${dia}/${mes}/${ano} ${HH}:${mm}`;
  } else {
    return `${dia}/${mes}/${ano}`;
  }
}

export function hasInterceção(a1: string[], a2: string[]): boolean {
  return a1.some((papel) => a2.includes(papel));
}
