import { useContext, useEffect, useState } from 'react';
import { Registro, Usuário } from 'src/dataModel';
import { Firebase, AuthState, OrderBy } from 'src/firebase-local';
import { AmbienteContext } from 'src/AmbienteContext';

type UseRegistrosReturn<R> = [R[], React.Dispatch<React.SetStateAction<R[]>>];
type UseUmRegistroReturn<R> = [R];

/**
 * Hook responsável por recuperar um único registro do Firebase
 * @param collectionPath Caminho da coleção firebase que possui o registro
 */
export function useUmRegistro<R extends Partial<Registro>>(
  documentPath: string
): UseUmRegistroReturn<R> {
  const { firebase } = useContext(AmbienteContext);
  const [regitro, setRegistro] = useState<R>();

  useEffect(() => {
    let subscribed = true;

    (async () => {
      if (!firebase)
        throw new Error("firebase não definido em 'FirebaseContext'");

      firebase
        .one<R>(documentPath)
        .then((registro) => subscribed && setRegistro(registro));
    })();

    return () => {
      subscribed = false;
    };
  }, [firebase, documentPath]);

  return [regitro];
}

/**
 * Hook responsável por recuperar registros do Firebase
 *
 * @param collectionPath Caminho da coleção firebase que possui o registro
 * @param options
 */
export function useRegistros<R extends Partial<Registro>>(
  collectionPath: string,
  options: { orderBy?: OrderBy[] } = { orderBy: [] }
): UseRegistrosReturn<R> {
  const { firebase } = useContext(AmbienteContext);
  const [regitros, setRegistros] = useState<R[]>();

  // Conteúdo de options.orderBy?.[0] deve ser verificado, pois options.orderBy[0] ser nulo ou indefinido.
  const [fieldPath, direction] = options.orderBy?.[0] || [];

  useEffect(() => {
    let subscribed = true;
    (async () => {
      if (!firebase)
        throw new Error("firebase não definido em 'FirebaseContext'");

      const orderBy: OrderBy[] | undefined =
        fieldPath && direction ? [[fieldPath, direction]] : undefined;

      firebase
        .many<R>(collectionPath, undefined, { orderBy })
        .then((registros) => {
          subscribed && setRegistros(registros);
        });
    })();

    return () => {
      subscribed = false;
    };
  }, [firebase, collectionPath, fieldPath, direction]);

  return [regitros, setRegistros];
}

type Defaults = { [prop: string]: null };

/**
 * Hook responsável por definir métodos para edição de registros no Firebase.
 *
 * Parâmetro 'defaultValues' garante que nenhum dos campos seja 'undefined'.
 * Isso para garantir que ordenação de registros do firebase não desconsidere
 * registros por falta de campos.
 *
 * @param collectionPath Caminho da coleção firebase que possui o registro
 * @param defaultValues Objeto com todos os campos nulos
 */
export function useEditor<R extends Registro>(
  collectionPath: string,
  defaultValues: R & Defaults
) {
  const { firebase } = useContext(AmbienteContext);

  const saveRegistro = async (registro: Partial<R>, novo?: boolean) => {
    try {
      return await firebase.set<R>(
        collectionPath,
        { ...defaultValues, ...registro },
        novo
      );
    } catch (e) {
      const message = `Erro ao salvar: ${e.message}`;
      alert(message);
    }
  };

  return [saveRegistro];
}

export function useAuth(firebase: Firebase) {
  const [state, setState] = useState<AuthState>({ loading: 'usuário' });

  //Identificação usuário firebase
  useEffect(() => {
    if (!firebase) return;

    return firebase.authChange((user) =>
      setState({ user, loading: user ? 'papeis' : false })
    );
  }, [firebase]);

  //Identificação do papel do usuário a partir da coleção de usuários
  useEffect(() => {
    if (state.loading == 'papeis') {
      const papeis_usuarios = firebase
        .many<Usuário>('usuarios', [['email', '==', state.user.email]])
        .then((usuários) =>
          usuários[0]?.papeis?.length > 0 ? [...usuários[0].papeis] : []
        )
        .catch((e) => {
          alert(e);
          return [];
        });

      // TODO remover definição de usuário da coleção de clientes na EndTest
      const papel_cliente = firebase
        .many<Registro>('clientes', [['email', '==', state.user.email]])
        .then((clientes) => (clientes.length > 0 ? ['cliente'] : []))
        .catch((e) => {
          alert(e);
          return [];
        });

      Promise.all([papeis_usuarios, papel_cliente]).then(([p1, p2]) =>
        setState({ ...state, loading: false, papeis: [...p1, ...p2] })
      );
    }
  }, [firebase, state]);

  return state;
}

export function useDownloadUrl(path: string) {
  const { firebase } = useContext(AmbienteContext);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    let subscribed = true;

    firebase.downloadURL(path).then((url) => subscribed && setUrl(url));

    return () => {
      subscribed = false;
    };
  }, [firebase, path]);

  return url;
}
