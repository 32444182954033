import {
  AmbienteContext,
  Registro,
  Salvar,
  Tabela2 as Tabela,
  useEditor,
  useRegistros,
} from '@velits/velits-lib';
import React, { useContext } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

export interface Cliente extends Registro {
  id: string;
  nome: string;
  responsavel: string;
  email: string;
  senha: string;
}

function validateMessages(c: Partial<Cliente>) {
  const m: string[] = [];
  if (!c.email) m.push('O E-mail é obrigatório');
  if (!c.senha || c.senha.length < 6)
    m.push('A Senha deve ter pelo menos 6 caracteres');

  return m;
}

export function Clientes(): JSX.Element {
  const { firebase } = useContext(AmbienteContext);
  const { url } = useRouteMatch();
  const [clientes, setClientes] = useRegistros<Partial<Cliente>>('clientes');
  const [saveCliente] = useEditor<Cliente>('clientes', {
    id: null,
    nome: null,
    responsavel: null,
    email: null,
    senha: null,
  });

  return (
    <Tabela
      botãoNovo='Novo Cliente'
      registros={clientes}
      setRegistros={setClientes}
      show={['salvar', 'cliente', 'responsável', 'email', 'senha', 'relatório']}
    >
      {{
        salvar: {
          render: function _Salvar() {
            return (
              <Salvar
                onClick={async (cliente: Partial<Cliente>, setCliente) => {
                  //Validação dos dados
                  const msgs = validateMessages(cliente);
                  if (msgs.length !== 0) {
                    alert(msgs.join('\n'));
                    return;
                  }

                  //Manipulação do usuário ligado ao cliente
                  const novo = !cliente.id;
                  const { id, email, senha } = cliente;
                  if (novo) {
                    cliente.id = await firebase.createUser({ email, senha });
                  } else {
                    await firebase.updateUser({ id, email, senha });
                  }

                  //Inserção do registro do cliente
                  await saveCliente(cliente, novo)
                    .then(setCliente)
                    .then(() => alert('Cliente salvo'));
                }}
              />
            );
          },
        },
        cliente: { title: 'Nome Cliente', name: 'nome' },
        responsável: { title: 'Responsável', name: 'responsavel' },
        email: { title: 'E-mail*', name: 'email' },
        senha: { title: 'Senha*', name: 'senha' },
        relatório: {
          render: function _Link(cliente) {
            return !cliente.id ? (
              ''
            ) : (
              <Link to={`${url}/${cliente.id}/relatorios`}>Relatórios</Link>
            );
          },
        },
      }}
    </Tabela>
  );
}
