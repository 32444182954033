import React, { Dispatch } from 'react';
import { Registro } from '../dataModel';
import { Input, Atributos } from './coluna';
import { FormContext } from './FormContext';

type Célula<R> = (
  registro: Partial<R>,
  setRegistro: Dispatch<Partial<R>>
) => JSX.Element | string;

type Render<R> = { render: Célula<R>; title?: string };
function isRender<R>(obj: Render<R> | Atributos): obj is Render<R> {
  return (obj as Render<R>).render !== undefined;
}

type Coluna<R> = Atributos | Render<R>;

type Colunas<R> = {
  [index: string]: Coluna<R>;
};

type Direction = 'desc' | 'asc' | null;
type SetOrderBy = React.Dispatch<
  React.SetStateAction<[string, Direction] | null>
>;

type TabelaProps<R, C extends Colunas<R>> = {
  registros: Partial<R>[];
  setRegistros: React.Dispatch<React.SetStateAction<Partial<R>[]>>;
  show: string[];
  botãoNovo?: string;
  orderBy?: [string, Direction];
  setOrderBy?: SetOrderBy;
  children: C;
};

export function Tabela<R extends Registro, C extends Colunas<R>>({
  registros,
  setRegistros,
  show,
  botãoNovo,
  orderBy,
  setOrderBy,
  children,
}: TabelaProps<R, C>) {
  const cabeçalhos = show.map((campo, key) => {
    const { title } = children[campo];

    return (
      <Cabeçalho key={key} {...{ campo, orderBy, setOrderBy }}>
        {title}
      </Cabeçalho>
    );
  });

  const linhas = registros?.map((regitro, idx) => (
    <Linha
      key={regitro.id || idx}
      registro={regitro}
      setRegistro={(registro: Partial<R>) => {
        registros[idx] = registro;
        setRegistros([...registros]);
      }}
      show={show}
    >
      {children}
    </Linha>
  ));

  const novo = botãoNovo && registros && (
    <div>
      <button aria-label='Novo' onClick={() => setRegistros((c) => [{}, ...c])}>
        {botãoNovo}
      </button>
    </div>
  );

  return (
    <>
      {novo}
      {registros && (
        <table>
          <thead>
            <tr>{cabeçalhos}</tr>
          </thead>
          <tbody>{linhas}</tbody>
        </table>
      )}
    </>
  );
}

type ArrowProps = { direction?: Direction };

/**
 * Seta para cima ou para baixo indicando ordenação de colunas.
 *
 * - Direction: direção da ordenação (default: null);
 *
 * @param props
 */
function Arrow({ direction = null }: ArrowProps) {
  return (
    <svg height='16px' viewBox='0 0 16 16' width='16px' fill='#ffffff'>
      <path d='M0 0h16v16H0V0z' fill='none' />
      {direction && (
        <path
          d={direction === 'asc' ? 'M7 14l5-5 5 5H7z' : 'M7 10l5 5 5-5H7z'}
        />
      )}
    </svg>
  );
}

type CabeçalhoProps = {
  campo: string;
  children: string;
  orderBy?: [string, Direction];
  setOrderBy?: SetOrderBy;
};

function Cabeçalho({ campo, children, orderBy, setOrderBy }: CabeçalhoProps) {
  if (children === '') return <th></th>;

  // Caso não haja ordenação
  if (!orderBy && !setOrderBy) return <th>{children}</th>;

  const [fieldPath, direction] = orderBy || [];

  return (
    <th onClick={() => reorder(campo)} style={{ cursor: 'pointer' }}>
      {children}
      {fieldPath === campo ? <Arrow direction={direction} /> : <Arrow />}
    </th>
  );

  function reorder(campo: string) {
    if (!setOrderBy) return;

    if (fieldPath !== campo) setOrderBy([campo, 'asc']);
    else if (direction === 'asc') setOrderBy([campo, 'desc']);
    else setOrderBy(null);
  }
}

type LinhaProps<R, C> = {
  registro: Partial<R>;
  setRegistro: Dispatch<Partial<R>>;
  children: C;
  show: (keyof C)[];
};

function Linha<R extends Registro, C extends Colunas<R>>({
  registro,
  setRegistro,
  show,
  children,
}: LinhaProps<R, C>) {
  return (
    <FormContext.Provider value={[registro, setRegistro]}>
      <tr>
        {show.map((campo, key) => {
          const render = getRender(children[campo]);
          return <td key={key}>{render(registro, setRegistro)}</td>;
        })}
      </tr>
    </FormContext.Provider>
  );
}

function getRender<R>(coluna: Coluna<R>): Célula<R> {
  /* eslint react/display-name: off  */
  if (isRender(coluna)) {
    return coluna.render;
  } else {
    return () => <Input {...coluna} />;
  }
}
