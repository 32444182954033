import React from 'react';

export function Versão() {
  return (
    <div
      style={{ position: 'absolute', right: 0, top: 0, fontSize: 'xx-small' }}
    >
      1.2.0
    </div>
  );
}
