import React from 'react';
import {
  Link as LinkRouter,
  LinkProps,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from 'react-router-dom';
import { AplicaçãoContext, LinkNavBar } from './AplicaçãoContext';

export type RoteadorShow = { show: string[]; curinga: string; links: string[] };

type RoteadorProps = {
  /**
   * - 'children' correspondem às rotas disponíveis ao roteador.
   * - 'children' aceitam somente 'path' do tipo 'string' para manter
   * compatibilidade com o Redirect.to do 'curinga'.
   * - Toda rota a ser exibida como link deve possuir o atribuito 'text'".
   */
  children: { [index: string]: RouteProps & { path: string; text?: string } };
} & RoteadorShow;
export function Roteador({
  children,
  show,
  curinga,
  links: showLinks,
}: RoteadorProps) {
  const links: LinkNavBar[] = showLinks.map((showLink) => {
    const { path: to = '', text } = children[showLink];

    if (text === undefined) {
      throw new Error(
        "Toda rota exibida como link deve possuir o atribuito 'text'"
      );
    }

    return { text, props: { to } };
  });

  return !show.length ? (
    <div>Carregando rotas</div>
  ) : (
    <AplicaçãoContext.Provider value={{ links }}>
      <Switch>
        {show.map((campo, key) => (
          <Route key={key} {...children[campo]} />
        ))}
        {/* Rota curinga: */}
        <Route path='*'>
          <Redirect to={children[curinga].path} />
        </Route>
      </Switch>
    </AplicaçãoContext.Provider>
  );
}

export function Link(props: LinkProps) {
  return <LinkRouter {...props}></LinkRouter>;
}
